import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faSquare } from "@fortawesome/free-solid-svg-icons";
import Datetime from "react-datetime";
import moment from "moment";

import { Table } from "@themesberg/react-bootstrap";

import {
  Card as CardAntd,
  Input,
  Tooltip,
  Select,
  Button,
  Tag,
  // Table,
  Space,
  Menu,
  Dropdown,
  Badge,
  Pagination,
  Spin,
  DatePicker,
  Divider,
  Skeleton,
} from "antd";
import dayjs from "dayjs";

import reportSvc from "../services/reportSvc";

import { getLoginData } from "../services/authSvc";
import customerSvc from "../services/customerSvc";
import userSvc from "../services/userSvc";
import { customStyles } from "../utils/SelectUtils";
// import Select from "react-select";
import NavTabs from "../components/NavTabs";
import { useHistory } from "react-router-dom";
import LaporanInformationCard from "../components/LaporanInformationCard";
import { SearchOutlined } from "@ant-design/icons";
import AsyncSelect from "../components/template/AsyncSelect";
const { RangePicker } = DatePicker;
moment.tz.setDefault("Asia/Bangkok");

export default () => {
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(
    moment().subtract(2, "days").format("YYYY-MM-DD")
  );
  const [searchTerm, setSearchTerm] = useState("");
  const searchInputRef = useRef(null);
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [reportExports, setReportExport] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [operators, setOperators] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedOperator, setSelectedOperator] = useState(null);
  const [daysInRange, setDaysInRange] = useState(0);
  const { user } = getLoginData();

  const fetchCustomers = async () => {
    if (user.role === "admin") {
      const resp = await customerSvc.getCustomer({
        params: {
          page: 0,
          limit: 1000,
        },
      });
      if (resp.status === 200) {
        setCustomers(resp.data.data);
      }
    }
  };

  const fetchOperators = async () => {
    const resp = await userSvc.getUser({
      params: {
        role: "operator",
        page: 0,
        limit: 1000,
      },
    });
    if (resp.status === 200) {
      setOperators(resp.data.data);
    }
  };

  // Function to calculate and update the days in range
  const updateDaysInRange = () => {
    const start = moment(startDate);
    const end = moment(endDate);
    const days = end.diff(start, "days") + 1; // Add 1 to include both start and end dates
    setDaysInRange(days);
  };

  const fetchReportsExport = async () => {
    try {
      setIsLoading(true);
      const respdata = await reportSvc.getReportExportDaily({
        params: {
          start_date: startDate,
          end_date: endDate,
          query: searchTerm,
          id_pelanggan: selectedCustomer,
          id_user: selectedOperator,
        },
      });
      if (respdata.status === 200) {
        setReportExport(respdata.data.data);
      }

      setIsLoading(false);
    } catch (err) {}
  };

  useEffect(() => {
    updateDaysInRange();
  }, [startDate, endDate]);

  useEffect(() => {
    console.log(selectedCustomer, selectedOperator);
    fetchReportsExport();
  }, [startDate, endDate, selectedCustomer, selectedOperator]);

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (!isFirstRender.current) {
      let timerId;
      const fetchData = () => {
        fetchReportsExport();
      };
      const delayedFetch = () => {
        clearTimeout(timerId);
        timerId = setTimeout(fetchData, 500);
      };
      delayedFetch();
      return () => {
        clearTimeout(timerId);
      };
    } else {
      isFirstRender.current = false;
    }
  }, [searchTerm]);

  const generateDateLabels = (startDate, daysInRange) => {
    const labels = [];
    for (let i = 0; i < daysInRange; i++) {
      const date = moment(startDate).add(i, "days");
      labels.push(date.format("D MMM"));
    }
    return labels;
  };

  const handleChangeDate = (dates) => {
    if (dates && dates.length === 2) {
      setStartDate(dates[0].format("YYYY-MM-DD"));
      setEndDate(dates[1].format("YYYY-MM-DD"));
    }
  };

  const handleTabChange = () => {
    useHistory.push("/");
  };

  return (
    <div className="align-items-center py-4 w-100">
      <CardAntd style={{ width: "100%" }}>
        <NavTabs
          activeTab={"/report-summary"}
          onTabChange={handleTabChange}
          sideTab={"infografis"}
        />
        <br />
        <div className="d-flex flex-wrap justify-content-between mb-3 gap-2">
          <Input.Group style={{ flexGrow: 1, flexBasis: "300px" }}>
            <RangePicker
              style={{ width: "100%" }}
              size="middle"
              onChange={handleChangeDate}
              allowClear={false}
              format="YYYY-MM-DD"
              value={[
                dayjs(startDate, "YYYY-MM-DD"),
                dayjs(endDate, "YYYY-MM-DD"),
              ]}
            />
          </Input.Group>

          <Input.Group style={{ flexGrow: 1, flexBasis: "300px" }}>
            <AsyncSelect
              placeholder="Semua Operator"
              onDropdownVisibleChange={() => {
                if (operators.length === 0) fetchOperators();
              }}
              onChange={setSelectedOperator}
              value={selectedOperator}
              options={operators.map((data) => ({
                key: data.id_user,
                value: data.id_user,
                label: data.name,
              }))}
              loading={operators.length === 0}
            />
          </Input.Group>
          <Input.Group style={{ flexGrow: 1, flexBasis: "300px" }}>
            <Input
              size="middle"
              placeholder="Cari operator, ATM / kode ATM.."
              prefix={<SearchOutlined />}
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
              className="me-1"
              ref={searchInputRef}
            />
          </Input.Group>
          {user?.role === "admin" && (
            <Input.Group style={{ flexGrow: 1, flexBasis: "300px" }}>
              <AsyncSelect
                placeholder="Semua Kantor Cabang"
                onDropdownVisibleChange={() =>
                  customers.length === 0 && fetchCustomers()
                }
                onChange={setSelectedCustomer}
                value={selectedCustomer}
                options={customers.map((data) => ({
                  key: data.id_pelanggan,
                  value: data.id_pelanggan,
                  label: data.name,
                }))}
                loading={customers.length === 0}
              />
            </Input.Group>
          )}
        </div>
        <LaporanInformationCard />
        <Divider />
        <div style={{ overflowX: "auto", display: "visible" }}>
          {isLoading ? (
            <Skeleton.Input
              active
              block
              style={{ width: "100%", height: "80vh", marginBottom: "1rem" }}
            />
          ) : (
            <div
              style={{
                maxHeight: "60vh",
                overflowY: "auto",
              }}
              className="custom-scrollbar"
            >
              <Table
                id="export-table"
                style={{
                  minWidth: "100%",
                  borderCollapse: "collapse",
                  border: "1px solid #ccc",
                }}
              >
                <colgroup>
                  <col style={{ borderRight: "1px solid #ccc" }} />
                  <col style={{ borderRight: "1px solid #ccc" }} />
                  <col style={{ borderRight: "1px solid #ccc" }} />
                  <col style={{ borderRight: "1px solid #ccc" }} />
                  {Array.from({ length: daysInRange * 3 }, (_, index) => (
                    <col
                      key={index}
                      style={{ borderRight: "1px solid #ccc" }}
                    />
                  ))}
                </colgroup>
                <thead
                  style={{
                    position: "sticky",
                    top: 0,
                    backgroundColor: "white",
                    zIndex: 1,
                  }}
                >
                  <tr>
                    <th
                      style={{
                        padding: "8px",
                        textAlign: "center",
                        fontWeight: "bold",
                        borderBottom: "1px solid #ccc",
                        verticalAlign: "middle",
                      }}
                      colSpan={5}
                    >
                      INFORMASI ATM
                    </th>
                    <th
                      style={{
                        padding: "6px",
                        textAlign: "center",
                        fontWeight: "bold",
                        borderBottom: "1px solid #ccc",
                        borderRight: "1px solid #ccc",
                      }}
                      colSpan={daysInRange ? daysInRange * 3 : 0}
                    >
                      TANGGAL & KUNJUNGAN {startDate} - {endDate}
                    </th>
                  </tr>
                  <tr>
                    <th
                      style={{
                        padding: "8px",
                        textAlign: "center",
                        fontWeight: "bold",
                        borderBottom: "1px solid #ccc",
                        verticalAlign: "middle",
                      }}
                      rowSpan={2}
                    >
                      No
                    </th>
                    <th
                      style={{
                        padding: "8px",
                        textAlign: "center",
                        fontWeight: "bold",
                        borderBottom: "1px solid #ccc",
                        verticalAlign: "middle",
                      }}
                      rowSpan={2}
                    >
                      ID ATM
                    </th>
                    <th
                      style={{
                        padding: "8px",
                        textAlign: "center",
                        fontWeight: "bold",
                        borderBottom: "1px solid #ccc",
                        verticalAlign: "middle",
                      }}
                      rowSpan={2}
                    >
                      NAMA ATM
                    </th>
                    <th
                      style={{
                        padding: "8px",
                        textAlign: "center",
                        fontWeight: "bold",
                        borderBottom: "1px solid #ccc",
                        verticalAlign: "middle",
                      }}
                      rowSpan={2}
                    >
                      KANTOR CABANG
                    </th>
                    <th
                      style={{
                        padding: "8px",
                        textAlign: "center",
                        fontWeight: "bold",
                        borderBottom: "1px solid #ccc",
                        verticalAlign: "middle",
                      }}
                      rowSpan={2}
                    >
                      Ring
                    </th>
                    {generateDateLabels(startDate, daysInRange).map(
                      (label, index) => (
                        <th
                          style={{
                            padding: "6px",
                            textAlign: "center",
                            borderBottom: "1px solid #ccc",
                            borderRight: "1px solid #ccc",
                          }}
                          key={index}
                          colSpan={3}
                        >
                          {label}
                        </th>
                      )
                    )}
                  </tr>
                  <tr>
                    {Array.from({ length: daysInRange }, (_, index) => (
                      <React.Fragment key={index}>
                        <th
                          style={{
                            padding: "6px",
                            textAlign: "center",
                            borderBottom: "1px solid #ccc",
                            borderRight: "1px solid #ccc",
                          }}
                        >
                          1
                        </th>
                        <th
                          style={{
                            padding: "6px",
                            textAlign: "center",
                            borderBottom: "1px solid #ccc",
                            borderRight: "1px solid #ccc",
                          }}
                        >
                          2
                        </th>
                        <th
                          style={{
                            padding: "6px",
                            textAlign: "center",
                            borderBottom: "1px solid #ccc",
                            borderRight: "1px solid #ccc",
                          }}
                        >
                          3
                        </th>
                      </React.Fragment>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {reportExports.map((item, index) => {
                    const { asset, laporans } = item;
                    const { kode, pelanggan, name, ring, just_on_holiday } =
                      asset;

                    return (
                      <tr key={index}>
                        {/* Asset details */}
                        <td
                          style={{
                            padding: "8px",
                            borderBottom: "1px solid #ccc",
                            borderRight: "1px solid #ccc",
                          }}
                        >
                          {index + 1}
                        </td>
                        <td
                          style={{
                            padding: "8px",
                            borderBottom: "1px solid #ccc",
                            borderRight: "1px solid #ccc",
                            whiteSpace: "wrap",
                          }}
                        >
                          {kode.replaceAll(",", " ")}
                        </td>
                        <td
                          style={{
                            padding: "8px",
                            borderBottom: "1px solid #ccc",
                            borderRight: "1px solid #ccc",
                          }}
                        >
                          {name}
                        </td>
                        <td
                          style={{
                            padding: "8px",
                            borderBottom: "1px solid #ccc",
                            borderRight: "1px solid #ccc",
                          }}
                        >
                          {pelanggan?.name ? pelanggan.name : "KC Dihapus"}
                        </td>
                        <td
                          style={{
                            padding: "8px",
                            borderBottom: "1px solid #ccc",
                            borderRight: "1px solid #ccc",
                          }}
                        >
                          Ring {ring}
                        </td>

                        {/* Loop through each day in the range */}
                        {Array.from({ length: daysInRange }, (_, dayIndex) => {
                          const date = moment(startDate).add(dayIndex, "days");
                          const dateString = date.format("YYYY-MM-DD");
                          const dataLaporan = laporans[dateString] || [];

                          // Render cells for each kunjungan in a day
                          const cells = Array.from(
                            { length: 3 },
                            (_, laporanIndex) => {
                              let backgroundColor = "#FF0000";
                              if (!laporans[dateString] && just_on_holiday)
                                backgroundColor = "#FFFF00";
                              if (laporanIndex >= parseInt(ring))
                                backgroundColor = "#000000";

                              const laporan = dataLaporan[laporanIndex];

                              if (laporan && laporan.is_temuan !== undefined) {
                                backgroundColor = laporan.is_temuan
                                  ? "#0070C0"
                                  : "#92D050";
                              }

                              return (
                                <td
                                  key={laporanIndex}
                                  style={{
                                    padding: "8px",
                                    backgroundColor,
                                    color: "#000000",
                                    fontSize: "10px",
                                    border: " solid #ccc",
                                  }}
                                >
                                  {laporan && laporan.id_laporan ? (
                                    <a
                                      href={`report-detail/${laporan.id_laporan}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      style={{
                                        color: "#000000",
                                        fontSize: "10px",
                                      }}
                                    >
                                      {laporan.date_done
                                        ? moment
                                            .utc(laporan.date_done)
                                            .format("HH:mm")
                                        : ""}
                                    </a>
                                  ) : null}
                                </td>
                              );
                            }
                          );

                          return cells;
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          )}
        </div>
      </CardAntd>
    </div>
  );
};
